.feature {
}

.features-pre-title {
    margin-top: 20px;
}

.features-main {
    display: flex;
    margin-top: 100px;
}

.features {
    width: 33%;
}

.features-components {
    /* width: 70%; */
}
.features-center {
    margin: 0 auto;
}
.features-right {
    margin-top: 200px;
    
}
.features-left {
    margin-top: 200px;
    /* margin-left: 30%; */
}

.features-title {
    margin-top: 25px;
    margin-bottom: 20px;
    font-weight: 700;
}

.feature-title {
    margin-top: 25px;
    margin-bottom: 20px;
    font-weight: 700;
}

.features-description {
    line-height: 23px;
    font-size: 13px;
}

.features-description-add {
    font-weight: 700;
}


.feature-img {
    width: 70%;
}

@media(max-width:960px)  {
    .feature {
    }
    .features-main {
        display: flex;
        margin-top: 50px;
        flex-direction: column;
    }
    .features {
        width: 70%;
        margin: 0 auto;
    }
    .feature-title {
        margin-top: 25px;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 36px;
    }

    .features-components {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 50px;
    }
    .features-description {
        line-height: 23px;
        font-size: 13px;
        margin-bottom: 30px;
    }
    .features-left {
        margin-top: 0px;
    }
    .features-right {
        margin-top: 0px;
    }
  
}