.explore-component {
  /* height: 200px; */
}

.whitelist-msg {
  margin-top: 16px;
}

.whitelist-addr {
  margin-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
