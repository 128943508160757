.about {
  display: flex;
  flex-direction: row-reverse;
}

.about-component {
  width: 50%;
}

.explore-component-left {
  margin-top: -65px;
  max-width: 500px;
}
.explore-component-right {
  width: 80%;
  margin-left: 30px;
}

.about-pre-title {
  margin-top: 90px;
  text-align: left;
}

.about-title {
  padding-top: 25px;
  text-align: left;
  padding-bottom: 30px;
}

.about-description {
  text-align: left;
  line-height: 26px;
  font-size: 15px;
}

@media (max-width: 1000px) {
  .about {
    flex-direction: column;
  }
  .about-component {
    width: 100%;
  }
  .about-pre-title {
    text-align: center;
  }
  .about-title {
    text-align: center;
  }
  .about-description {
    text-align: center;
    line-height: 26px;
    font-size: 15px;
    margin-bottom: 30px;
  }
  .explore-component-right {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 520px) {
  .explore-component-left {
    margin-top: -46px;
    max-width: 300px;
  }
}
