header.header-main {
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  background-color: #040f15;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(8, 236, 154, 0.08);
  padding: 0 30px;
}

.header-component {
  display: auto;
}

.header-menu {
  display: flex;
  margin-right: 20px;
  line-height: 60px;
}
.header-button {
  padding: 0;
  /* margin-top: 10px; */
}

.header-connect {
  width: 20px;
  height: 40px;
  font-size: 10px;
}

.header-menu-component {
  display: auto;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  padding: 0 8px;
}
.header-menu-component:hover {
  opacity: 0.8;
}

.button-header {
  /* padding: 20px 10px; */
  width: 200px;
  font-weight: 700;
}

.header-menu-button {
  display: flex;
  align-items: center;
}

.connect {
  max-width: 140px;
  font-size: 10px;
  padding: 10px 12px;
}

.header-logo {
  width: 140px;
}

@media (max-width: 800px) {
  .header-menu {
    display: none;
    /* width: 380px;
    margin-right: 20px;
    line-height: 60px; */
  }
  /* .header-menu-button {
    display: flex;
    margin-right: 30px;
  } */
}

@media (max-width: 576px) {
  .header-menu {
    display: none;
  }
  .header-menu-button {
    display: flex;
    align-items: center;
  }
  .button-header {
    padding: 10px;
    margin-left: 10px;
    width: 100px;
  }
}
