.details {
}

.details-medals {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
}

.details-medals-component-main {
    background-color: rgba(62, 56, 56, 0.1);
    border-radius: 12px;
    padding: 16px 12px;
    margin: 0px 13px;
}
.details-title {
    margin-top: 20px;
    margin-bottom: 100px;
}

.details-medals-components {
    display: flex;
    align-items: center;
}
.medals-image {
    width: 40px;
}

.details-medals-components-title {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 600;
}

.details-medals-component-description {
    margin-top: 12px;
    font-size: 12px;
    text-align: left;
}

.font-700 {
    font-weight: 700;
}

@media(max-width:960px)  {

    .details-title {
        margin-top: 30px;
        margin-bottom: 60px;
    }
    .details-medals {
        flex-direction: column;
    }

    .details-medals-component-description {
        font-size: 16px;
        line-height: 20px;
    }
    .details-medals-component-main {
        background-color: rgba(62, 56, 56, 0.1);
        border-radius: 12px;
        padding-left: 12px;
        padding-top: 16px;
        padding: 20px;
        margin-bottom: 20px;
        margin: 12px 0;
    }
  
}

@media(max-width:520px)  {
    .details-medals-component-description {
    font-size: 14px;
    }
}