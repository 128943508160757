/* 全体 */
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./font/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
}

.common {
  background-color: #060c0e;
  color: #ffffff;
  font-family: 'Plus Jakarta Sans';
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.green-text {
  color: #08ec9a;
}

.description-22 {
  font-size: 22px;
}

.description-20 {
  font-size: 20px;
}

.description-18 {
  font-size: 18px;
}

.explore-title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 3px;
}

.explore-pre-title {
  font-size: 15px;
  opacity: 0.7;
}

.button-common {
  position: relative;
  /* padding: 12px 72px 12px 12px; */
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.button-common:hover {
  opacity: 0.8;
}

.green-button {
  background-color: #08ec9a;
  border: 1.5px solid #08ec9a;
}

.gray-button {
  background-color: gray;
  border: 1.5px solid gray;
}
.transparent-button {
  background-color: transparent;
  color: #08ec9a;
}
.button-deco-common {
  width: 4.16px;
  height: 4.16px;
  background: rgba(53, 61, 65, 0.7);
}

/* top: calc(5px + 0px);の部分、本当はpaddingが良い。
　　うまくいかなかったので仮の状態 */
.button-deco-first {
  position: absolute;
  top: calc(5px + 0px);
  left: calc(5px + 8.33px);
}

.button-deco-second {
  position: absolute;
  top: calc(5px + 0px);
  left: calc(5px + 0px);
}

.button-deco-third {
  position: absolute;
  left: calc(5px + 4.16px);
  top: calc(5px + 4.16px);
}

.button-deco-forth {
  position: absolute;
  top: calc(5px + 8.33px);
  left: calc(5px + 0px);
}

.icon {
  width: 60%;
}

.icon-discord {
  width: 21px;
}
.container {
  padding: 0 40px;
  margin: 0 auto;
  max-width: 1080px;
  text-align: center;
}

@media (max-width: 576px) {
  .container {
    padding: 0 20px;
  }
}
