footer.footer {
  background-color: #040F15;
  box-shadow: 0px 0px 20px rgba(8, 236, 154, 0.08);
}

.footer-component {
  text-align: center;
}

.footer-component-logo {
  border-bottom: 1px solid #152329;
  padding-top: 70px;
  padding-bottom: 70px;
}
.footer-component-icon-main {
  width: 160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 70px;
}
.footer-compnent-icon {
  position: relative;
  border: 1px solid  rgba(255, 255, 255, 0.16);;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.footer-compnent-icon:hover {
  opacity: 0.8;
}

.footer-compnent-icon-circle-twitter {
position: absolute;
top: 5px;
left: 5px;
}
.footer-compnent-icon-circle-telegram {
  position: absolute;
  top: 5px;
  left: 3px;
  }
  .footer-compnent-icon-circle-medium {
    position: absolute;
    top: 4px;
    left: 4px;
    }

  .footer-compnent-icon-circle-discord {
      position: absolute;
      top: 5px;
      left: 4px;
  }

.footer-component-company {
  padding-bottom: 70px;
}