.map {
}
.map-pre-title {

}
.map-title {
    margin-top: 30px;
    margin-bottom: 5px;
}

.map-component {
    display: flex;
}

.map-components {
    /* border: 1px solid orangered; */
    width: 50%;
}

.component-1st {
    padding-left: 5%;
}

.map-1st-block {
}

.map-center {
    line-height: 200px;
}

.font-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.map-2nd-block {
}

.map-3rd-block {
}

.map-big-font {
    font-size: 30px;
    font-weight: 600;
}

.map-normal-font {
    font-size: 20px;
    font-weight: 400;
}

.map-big-number {
    color: #08EC9A;
    font-size: 72px;
    font-weight: 800;
    text-align: left;
}

.map-big-number-transparent {
    color: transparent;
    font-size: 72px;
    font-weight: 800;
    -webkit-text-stroke: 2px #08EC9A;
    text-align: left;
}

.map-image {
    width: 100%;
}

.map-pc-image {
    width: 100%;
    
}

.map-smart {
    display: none;
}
.map-smart {
    display: none;
}

@media(max-width:1000px)  {
    .map {
    }
    .map-component {
        display: flex;
        flex-direction: column;
    }
    .map-components {
        /* border: 1px solid orangered; */
        width: 100%;
    }
    .map-pre-title {
        margin-top: 40px;

    }
    .map-title {
        margin-top: 30px;
        margin-bottom: 60px;
    }
}

@media(max-width:800px)  {
    .map {
        display: none;
    }
}