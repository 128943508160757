.faq {
    display: flex;
}
.faq-left {

}

.faq-component {
    width: 50%;
    margin: 0 auto;
}
.faq-main-title {
    text-align: left;
    margin-bottom: 50px;
}
.faq-title {
    display: flex;
    font-size: 13px;
}
.faq-title-component {
    margin-bottom: 30px;
}
.faq-title-title {
    display: flex;
    border: 1px solid #152329;
    align-items: center;
    width: 800px;
    text-align: left;
    font-weight: 600;
    color: #08EC9A;
    padding: 5px 20px;
    border-radius: 8px 0px 0px 8px;
}
.faq-title-first {
    padding-bottom: 10px;
}

.faq-title-title-open {
    display: flex;
    border-left: 1px solid #152329;
    border-top: 1px solid #152329;
    align-items: center;
    width: 800px;
    text-align: left;
    font-weight: 600;
    color: #08EC9A;
    padding: 5px 20px;
    border-radius: 8px 0px 0px 0px;
}
.faq-title-expand {
    background: #152329;
    color: #FFFFFF;
    cursor: pointer;
    max-width: 84px;
    line-height: 40px;
    text-align: center;
    border-radius: 0px 8px 8px 0px;
    align-items: center;
    padding: 4px 21px;
    font-size: 25px;
    padding-bottom: 10px;
}

.faq-title-expand:hover {
    background: #08EC9A;
}

.faq-title-description {
    text-align: left;
    line-height: 22px;
    border-left: 1px solid #152329;
    border-right: 1px solid #152329;
    border-bottom: 1px solid #152329;
    font-size: 12px;
    padding: 18px 46px;
    border-radius: 0px 0px 8px 8px;
}

.faq-image {
    width: 70%;
}

@media(max-width:860px)  {
    .faq {
        display: flex;
        flex-direction: column;
    }
    .faq-component {
        width: 100%;
    }
}