.collection {
    display: flex;
    margin-top: 100px;
}

.collection-component {
    
}

.collection-main {
    width: 30%;
}

.collection-right {
    width: 70%;
}
.collection-images {
    display: flex
}
.collection-image {
    /* width: 25%; */
    width: 100%;
    padding-left: 20px;
    /* margin-left: 20px; */
}

.collection-image-left {
    width: 25%;
    padding-left: 20px;
}
.collection-left {
    width: 70%;
}

.collection-title {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 20px;
}

.collection-title-component {
    padding-bottom: 10px;
}

.collection-pre-title {
    text-align: left;
}

.collection-direction {
    display: flex;
    width: 75px;
    justify-content: space-between;
}

.collection-direction-side{
    background: #152329;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    cursor: pointer;
}
.collection-direction-side:hover {
    opacity: 0.8;
}

.collection-direction-left{
    border-radius: 4px 0px 0px 4px;
}

.collection-direction-right{
    border-radius: 0px 4px 4px 0px;
}

/* .slick-prev:before,
.slick-next:before {
  display: none;
} */

@media(max-width: 1000px) { 
    .collection {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }
    .collection-pre-title {
        text-align: center;
    }
    .collection-component {
        width: 100%;
    }
    .collection-left {
        width: 100%;
        margin: 0 auto;
    }
    .collection-title {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .collection-direction {
        display: flex;
        width: 100px;
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .collection-image-left {
        width: 25%;
        padding-left: 0px;
    }
    .slide-image {
        width: 85%;
        margin: 0 auto;
    }
}