.mint-page {
  display: flex;
  width: 100%;
}
.mint-page-left {
  width: 60%;
}
.mint-page-right {
  width: 40%;
}

.mint-page-image {
  width: 100%;
}

.mint-page-title {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 38px;
  text-align: left;
  font-weight: 800;
}

.mint-page-2nd {
  margin-top: 10px;
  line-height: 55px;
}

.mint-page-right-main {
}

.mint-page-pack {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
  text-align: center;
}

.mint-page-description-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
  text-align: center;
}
.mint-page-description {
  text-align: left;
  line-height: 23px;
  font-size: 16px;
}
.mint-page-description-description {
  font-size: 12px;
  text-align: center;
}
.mint-page-button {
}

.left-button {
  text-align: left;
  margin-top: 10px;
}
.mint-page-input {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.small-button {
  border: 1px solid #08ec9a;
  background-color: #152329;
  cursor: pointer;

  /* background-color: transparent; */
  color: white;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  font-size: 12px;
}

.small-button:hover {
  opacity: 0.8;
}
.mint-button {
  margin-top: 30px;
}
.mint-page-info {
  margin-top: 8px;
}
.mint-page-info-main {
  display: flex;
  justify-content: space-between;
  width: 256px;
  margin: 0 auto;
}
.mint-page-info-number {
  line-height: 24px;
  height: 24px;
  font-size: 12px;
}

.mint-page-input-input {
  height: 36px;
  width: 170px;
  color: #08ec9a;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  border: 1px solid #08ec9a;
}

.mint-page-input-input-alart {
  height: 36px;
  width: 170px;
  color: red;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  border: 1px solid red;
}

.mint-page-input-button {
  height: 36px;
  width: 44px;
  background: #152329;
  font-size: 22px;
  border: 1px solid #152329;
  color: #ffffff;
  cursor: pointer;
}

.active-input-button:hover {
  height: 36px;
  width: 44px;
  background: #08ec9a;
  font-size: 22px;
  border: 1px solid #08ec9a;
  color: black;
}

.mint-page-input-button:active {
  height: 36px;
  width: 44px;
  background: darkgreen;
  font-size: 22px;
  border: 1px solid darkgreen;
  color: gray;
}

.mint-page-input-left {
  border-radius: 4px 0px 0px 4px;
}

.mint-page-input-right {
  border-radius: 0px 4px 4px 0px;
}

.mint-page-total-total {
  font-size: 12px;
  margin-top: 24px;
}
.mint-page-total-eth {
  font-size: 26px;
  padding-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
}

.mint-page-error-message {
  height: 40px;
  max-width: 260px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: red;
  text-align: left;
}

.connect-wallet {
  max-width: 250px;
  width: 100%;
  font-size: 14px;
  padding: 20px 12px;
}

.mint-page-icons {
  text-align: left;
  margin-top: 60px;
}

.mint-page-icon-main {
  width: 160px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 70px;
}
.icons {
  display: flex;
}

@media (max-width: 1000px) {
  .mint-page {
    flex-direction: column;
  }
  .mint-page-left {
    width: 100%;
  }
  .mint-page-right {
    width: 100%;
  }
  .mint-page-title {
    text-align: center;
  }
  .mint-page-description {
    width: 90%;
    margin: 0 auto;
  }
  .mint-page-button {
    text-align: center;
  }
  .mint-page-icon-main {
    width: 160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 70px;
  }
  .mint-page-right-main {
  }
  .mint-page-image-soldout {
    opacity: 0.5;
    width: 80%;
    left: 10%;
    position: absolute;
    z-index: 100000;
  }
}

.mint-page-right {
  position: relative;
}

@media (min-width: 1000px) {
  .mint-page-image-soldout {
    opacity: 0.5;
    left: 0px;
    width: 100%;
    position: absolute;
  }
}

/* TODO: displayを変更    display: none;*/
.mint-page-display {
}
